.spidergraph-container {
    width: 80%;
    margin: 2em auto;
    position: relative;
}

.metric-cluster {
    display: flex;

    .metric {

        .metric-value {
            font-size: larger;
            font-style:oblique;
            margin-left: .3em;
            margin-top: .4em;
        }
    }

    .cluster {
        margin: 0 auto;
    }
}

.methodologies {
    margin: 2em auto;

    h6 {
        color: #2ab683;
        margin-bottom: .1em;
    }
}

a,
a:active,
a:link,
a:visited,
a:hover {
    text-decoration: none;
    color: #14487c;
}

.legend {
    width: 100%;
    background-color: #fdfddb;
    padding: .5em 1em;
    margin-bottom: 1em;
    border-radius: 5px;
    // color: grey;

    :not(.legend__item:last-child) {
        margin-bottom: .3em;
    }

    .legend__item {
        display: flex;
        align-items: center;
    }
}