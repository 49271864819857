// .leaflet-marker-icon {
//     cursor: not-allowed;
// }

.noclick {
    cursor: not-allowed;
}

.dim {
    opacity: 0.7;
}

.loading {
    position: absolute;
    top: 50%;
    z-index: 9999;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #3fbc8e;
    font-size: xx-large;
    font-weight: 600;
    color: floralwhite;
    opacity: 1 !important;
    padding: 1em;
    border-radius: .5em;
}