.objectivesContainer {

    padding: 1em 1em;
    background-color: white;
    // display: flex;
    // justify-content: space-around;
    border-radius: 1em;
  align-items: center;

    .objectiveIcon {
        width: 200px;
        margin: 1em 1em;
    }   
}

$yellow: #eac526;
$pink: #fc7ecf;
$orange: #f48b76;
$green: #349370;
$grey: #9cacbc;
$blue: #1d82c0;
$red: #b11226;
$aliceblue: #8cd7e7;

.has-tooltip:hover + .tooltip,
.has-tooltip:focus + .tooltip,
.has-tooltip.hover + .tooltip {
  opacity: 1;
  transform: translate(-50%, -100%) scale(1) rotate(0deg);
  pointer-events: inherit;
}
.tooltip {
  display: block;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.75) rotate(5deg);
  transform-origin: bottom center;
  padding: 10px 30px;
  border-radius: 5px;
  background: rgba(0,0,0,0.75);
  text-align: center;
  color: white;
  transition: 0.15s ease-in-out;
  opacity: 0;
  width: 150%;
  max-width: 100vw;
  pointer-events: none;
  z-index: 1001;
  font-size: larger;
  &.yellow {
    background: rgba($yellow, 1);
    &:after {
      border-top: 5px solid rgba($yellow, 1);
    }
  }
  &.pink {
    background: rgba($pink, 1);
    &:after {
      border-top: 5px solid rgba($pink, 1);
    }
  }
  &.orange {
    background: rgba($orange, 1);
    &:after {
      border-top: 5px solid rgba($orange, 1);
    }
  }
  &.grey {
    background: rgba($grey, 1);
    &:after {
      border-top: 5px solid rgba($grey, 1);
    }
  }
  &.blue {
    background: rgba($blue, 1);
    &:after {
      border-top: 5px solid rgba($blue,1);
    }
  }
  &.red {
    background: rgba($red, 1);
    &:after {
      border-top: 5px solid rgba($red,1);
    }
  }
  &.green {
    background: rgba($green, 1);
    &:after {
      border-top: 5px solid rgba($green, 1);
    }
  }
  &.aliceblue {
    background: rgba($aliceblue, 1);
    &:after {
      border-top: 5px solid rgba($aliceblue,1);
    }
  }
  &:hover {
    opacity: 1;
    transform: translate(-50%, -100%) scale(1) rotate(0deg);
    pointer-events: inherit;
  }
  img {
    max-width: 100%;
  }
  &:after {
    content: '';
    display: block;
    margin: 0 auto;
    widtH: 0;
    height: 0;
    border: 5px solid transparent;
    border-top: 5px solid rgba(0,0,0,0.75);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
  }
}
.field {
  position: relative;
}

.methodology-text {
  padding: 2em;
  font-size: larger;
  background-color: rgba(20, 72, 124, 0.11);
  color: #1f5082;
  margin-bottom: 1em;
  border-radius: 10px;
}