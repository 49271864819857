body {
    background-color: #f3f3f3 !important;
}

.report-page {
    background-color: #fff;
    width: 210mm; // A4 Dimensions_
    min-height: 297mm; //      <--|
    margin: 0 auto 3em auto;
    font-family: Arial, Helvetica, sans-serif;
    
    .header {
        height: 125px;
        background-color: rgb(187, 212, 219);

        .logo {
            height: 30px;
            margin-left: 10px;
            margin-top: 10px;
        }

        .year {
            text-align: center;
            color: white;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
            font-weight: 600;
            font-style: italic;
        }

        .city-name {
            color: hsl(211.9, 75.5%, 28.8%);
            margin-left: auto;
            margin-right: 10px;
            font-weight: 600;
            font-family: Arial, Helvetica, sans-serif;
            text-align: center;
            letter-spacing: 3px;
            font-size: 30px;
        }
    }

    .body {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title-placeholder {
            width: 100%;
            height: 50px;
            background-color: hsl(0, 0%, 97.3%);
            color: hsl(212.2, 100%, 60.2%);
            font-size: 16px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .title-placeholder2 {
            width: 100%;
            height: 50px;
            background-color: hsl(0, 0%, 97.3%);
            color: hsl(212.2, 100%, 60.2%);
            font-size: 18px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .rankings-title {
            color: hsl(211.9, 75.5%, 28.8%);
            font-size: 16px;
            font-weight: 600;
            // font-style: italic;
            text-align: center;
            margin-bottom: 3px;
        }

        .report-overview {
            width: 98%;
            margin-top: 20px;
            margin-bottom: 10px;
            align-self: center;

            .report-entries {
                padding: 0 2em;
            }
        }

        .rankings {
            width: 48%;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .recommendations {
            width: 96%;
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
        }

        .rankings-graph {
            width: 88%;
            margin: 20px auto;
        }
            
        .entries {
            padding: 0 10px;
        }

        .recomendation-entries {
            padding: 0 20px;
            list-style: none;
            width: 50%;

            li::before {
                content: '\2022';
                color: rgb(52, 146, 255);
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }
        }

        .entry {
            display: flex;
            justify-content: space-between;
            margin-bottom: 3px;
            // padding: 10px;

            .entry-title {
                // color: aqua;
            }

            .entry-value {
                color: rgb(52, 146, 255);

            }
        }
    }

    .seperator {
        width: 100%;
        height: 1px;
        background-color: hsl(0, 0%, 85.9%);
        border-radius: 2px;
        margin-bottom: 5px;
    }

    .row {
        width: 90%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .row2 {
        width: 90%;
        display: flex;
        justify-content: space-between;

        .chart {
            width: 48%;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}